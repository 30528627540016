import { GrayColor, LightColor, FontSize } from '@web/atomic';
import styled from 'styled-components';
import { EmojiBallProps } from './emoji-ball.component';

const size = 52;
export const EmojiBallStyled = styled.span<EmojiBallProps>`
  margin: 0 auto;
  border-radius: ${size / 2}px;
  background-color: ${GrayColor.GrayXXLight};
  width: ${size}px;
  height: ${size}px;
  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;
  :after {
    content: '';
    transform: rotate(-45deg);
    position: absolute;
    width: ${size}px;
    height: 3px;
    border-radius: 1px;
    background: ${GrayColor.White};
    display: ${(props) => (props.forbidden ? 'block' : 'none')};
  }
`;

export const EmojiBallTextStyled = styled.span<EmojiBallProps>`
  font-size: ${FontSize.Large};
`;
