import * as React from 'react';

const SvgIcPlay = (props: React.SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg width={40} height={40} {...props}>
    <path
      d="M20 0C8.969 0 0 8.969 0 20s8.969 20 20 20 20-8.969 20-20S31.031 0 20 0zm6.443 21.01l-9.433 6.274c-.8.548-1.895-.043-1.895-1.01V13.727c0-.97 1.096-1.558 1.895-1.01l9.43 6.273c.718.462.718 1.557.002 2.02z"
      fill="#1D1841"
      fillRule="nonzero"
    />
  </svg>
);

export default SvgIcPlay;
