import { Row, H5 } from '@web/atomic';
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import * as React from 'react';
import { PartnersLogoComponentStyle, getPartnersLogoComponentH5Color, PartnersLogoComponentKind } from './PartnersLogoComponentStyle';

interface PartnersLogoComponentProps {
  imgs: IGatsbyImageData;
  kind: PartnersLogoComponentKind;
}

export const PartnersLogoComponent: React.FunctionComponent<PartnersLogoComponentProps> = (props) => {
  // TODO: Replace this with another view
  // 1. mudar a frase: Uma startup acelerada por => O app de sono mais bem avaliado do mundo
  // 2. colocar o logo das duas lojas virtuais, assim como temos na página seguinte (de quando a pessoa clica em Conhecer a solução) e podemos por um redirect para essa página tb, ou nem colocar redirect algum...
  return null;

  return (
    <PartnersLogoComponentStyle>
      <H5 color={getPartnersLogoComponentH5Color(props.kind)}>Uma startup acelerada por:</H5>
      <Row mt={true}>
        <GatsbyImage image={getImage(props.imgs)} alt="logo" imgStyle={{ objectFit: 'contain' }} />
      </Row>
    </PartnersLogoComponentStyle>
  );
};
