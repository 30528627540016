import { Body, H4, Hbox, Separator } from '@web/atomic';
import * as React from 'react';
import { EmojiBall, EmojiBallProps } from '../atm.emoji-ball';
import { EmojiTitleDescriptionStyled } from './emoji-text-vertical.component.style';

export interface EmojiTitleDescriptionProps extends EmojiBallProps {
  title?: string;
  description: string | JSX.Element;
}

export const EmojiTitleDescription: React.FunctionComponent<EmojiTitleDescriptionProps> = (props) => (
  <EmojiTitleDescriptionStyled>
    <Hbox>
      <Hbox.Item vAlign={'flex-start'} wrap>
        <EmojiBall emoji={props.emoji} forbidden={props.forbidden} />
      </Hbox.Item>
      <Hbox.Separator />
      <Hbox.Separator />
      <Hbox.Item>
        {props.title && <H4 cell>{props.title}</H4>}
        {props.title && <Separator />}
        <Body>{props.description}</Body>
      </Hbox.Item>
    </Hbox>
  </EmojiTitleDescriptionStyled>
);
