import { graphql } from 'gatsby';
import * as React from 'react';
import { HomeDetail } from '../modules/home/home-detail';
import { PageProps } from '../utils/local-types';

const Home: React.FunctionComponent<PageProps> = (props) => {
  return <HomeDetail {...props} />;
};

export default Home;

export const query = graphql`
  query HomeDetailV1 {
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;
