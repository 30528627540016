import { FontSize, FontWeight, FontFamily } from '@web/atomic';
import styled from 'styled-components';

export const TestimonialQuoteStyled = styled.q`
  quotes: '“' '”' '‘' '’';
  font-weight: ${FontWeight.Bold};
  font-size: ${FontSize.Small};
  font-family: ${FontFamily.Secondary};
  text-align: center;
`;

export const TestimonialCaptionStyled = styled.p`
  font-size: ${FontSize.XXSmall};
`;
