import assesmentDatasource from '@lp-root/src/data/assesment.datasource';
import { Assesment } from '@global/utils/remote-graphql-types';
import { hasWindow } from '@web/utils/platform';
import { appPaths } from '@lp-root/src/utils/path';
import { navigate } from 'gatsby';
import * as React from 'react';

/**
 * Get the user response from the assesment
 *
 * @param redirectIfNotSet redirect to home if no assesment is found
 */
export const useAssesment = (redirectIfNotSet = false): Assesment => {
  const [assesment, setAssesment] = React.useState<Assesment>();
  React.useEffect(() => {
    const currAssesment: Assesment = assesmentDatasource.assesment;
    if (!currAssesment && redirectIfNotSet && hasWindow()) {
      navigate(appPaths.assesment.path);
    }
    setAssesment(currAssesment);
  }, []);

  return assesment;
};
