import { GatsbyButton } from '@components/atm.button/button.component';
import { Blob2SectionStyled } from '@lp-root/src/components/atm.background/background.styled';
import { FootnoteRef } from '@lp-root/src/components/atm.footnote/footnote-ref.component';
import { Footnote, FootnoteProps } from '@lp-root/src/components/atm.footnote/footnote.component';
import LargeSeparatorRow from '@lp-root/src/components/atm.large-separator-row/large-separator-row.component';
import { SEO } from '@lp-root/src/components/legacy/mol.seo/seo.component';
import { EmojiTitleDescription } from '@lp-root/src/components/mol.emoji-title-description/emoji-text-vertical.component';
import IntroVideo from '@lp-root/src/components/org.intro-video/intro-video.component';
import IndexLayout from '@lp-root/src/components/org.layout/layout.component';
import { LoadableHcpLandingPageMediaSection } from '@lp-root/src/components/org.media-section/media-section.loadable';
import { appPaths } from '@lp-root/src/utils/path';
import { PageProps } from '@lp-src/utils/local-types';
import { Body, Col, FaIcon, FeaturedH2, Grid, HDisplay, LargeSeparator, Row, Separator } from '@web/atomic';
import { CardWrapper } from '@web/atomic/atm.wrapper/wrapper.component';
import { BooleanContainer } from '@web/atomic/legacy/obj.abstract-container';
import { useNative } from '@web/data/native.helper';
import { getCanonicalUrl } from '@web/utils/url';
import * as React from 'react';
import { useAssesment } from '../assesment/use-assesment';
import { LandingPageHeroSection } from './components/mol.landing-page-hero-section/landing-page-hero-section.component';
import { TestimonialSelectionSection } from './components/testimonial-section.component';

export const HomeDetail: React.FunctionComponent<PageProps> = (props) => {
  const native = useNative();
  const assesment = useAssesment();
  // BUSINESS_RULE: this button should not appear on the native side, otherwise apple will reject the app in the review process
  const primaryButtonConfig = native
    ? null
    : assesment && assesment.condition
    ? { text: 'Assinar', to: appPaths.subscription.path, id: 'ga-home-prices' }
    : { text: 'Conhecer a solução', to: appPaths.instructions.path, id: 'ga-home-top-cta' };

  const programPrimaryButtonConfig = native
    ? null
    : {
        text: 'Avaliar meu sono',
        to: appPaths.assesment.path,
        id: 'ga-home-top-cta',
      };
  return (
    <IndexLayout {...props}>
      <SEO
        socialMedia={{
          canonicalUrl: getCanonicalUrl(props.data.site.siteMetadata.siteUrl, props.location.pathname),
          title: 'Vigilantes do Sono - melhore sua saúde mental, sem remédios',
          description:
            'Conheça nosso programa digital de melhoria de sono baseado na Terapia Cognitivo-Comportamental para insônia (TCC-i). Melhore seu sono, sem remédios.',
        }}
      />
      {/* // HERO SECTION */}
      <BooleanContainer>
        {(bool) => (
          <>
            <LandingPageHeroSection
              title={'Melhore sua saúde mental, sem remédios.'}
              headline={
                <Body kind="light" role="tooltip" data-microtip-position="top" data-microtip-size="large">
                  Somos a primeira empresa brasileira de terapia digital com o protocolo terapêutico aprovado pela ANVISA.
                </Body>
              }
              // BUSINESS_RULE: this section should not appear on the native side, otherwise apple will reject the app in the review process
              primaryButton={primaryButtonConfig}
            />

            <Blob2SectionStyled id={'resultados-esperados'}>
              <Grid>
                <LargeSeparatorRow />
                {assesment && (
                  <Row center={'xs'}>
                    <Col xs={12} md={8} lg={3}>
                      <FeaturedH2>O que é a Vigilantes do Sono e como cuidamos do seu sono?</FeaturedH2>
                      <Body>Conheça a Sônia, nossa coach digital que irá te conduzir ao longo do programa</Body>
                      <Separator />
                    </Col>
                    <Col xs={12} md={8} lg={6}>
                      <IntroVideo
                        showVideoOnModal={bool.value}
                        context="home"
                        onThumbClick={bool.setTrue}
                        onCloseModalClick={bool.setFalse}
                      />
                    </Col>
                  </Row>
                )}
                <Row center={'xs'}>
                  <Col xs={12} md={8} lg={9}>
                    <FeaturedH2>
                      <span
                        role="tooltip"
                        data-microtip-position="top"
                        data-microtip-size="large"
                        aria-label={`
                    A Terapia Cognitivo-Comportamental (TCC) é considerada a melhor maneira de lidar com a insônia porque
                    ela não usa medicamentos e tem o melhor resultado para eliminação da insônia em longo prazo.
                  `}
                      >
                        O que esperar do programa <FaIcon.Question />
                      </span>
                    </FeaturedH2>
                    <Body center>
                      Baseado na TCC (Terapia Cognitivo Comportamental), nosso programa pode te ajudar:
                      <FootnoteRef index={0} /> <FootnoteRef index={1} />:
                    </Body>
                    <Row mt>
                      <Col xs={12} sm={6}>
                        <CardWrapper height="calc(100% - 16px)">
                          <HDisplay small>Pegar no sono mais rápido</HDisplay>
                        </CardWrapper>
                        <Separator />
                      </Col>
                      <Col xs={12} sm={6}>
                        <CardWrapper height="calc(100% - 16px)">
                          <HDisplay small>Ter um sono reparador</HDisplay>
                        </CardWrapper>
                        <Separator />
                      </Col>
                      <Col xs={12} sm={6}>
                        <CardWrapper height="calc(100% - 16px)">
                          <HDisplay small>Acordar menos durante a noite</HDisplay>
                        </CardWrapper>
                        <Separator />
                      </Col>
                      <Col xs={12} sm={6}>
                        <CardWrapper height="calc(100% - 16px)">
                          <HDisplay small>Se livrar da insônia </HDisplay>
                        </CardWrapper>
                        <Separator />
                      </Col>
                      <Col xs={12} sm={6}>
                        <CardWrapper height="calc(100% - 16px)">
                          <HDisplay small>Reduzir o consumo de medicamentos </HDisplay>
                        </CardWrapper>
                        <Separator />
                      </Col>
                      <Col xs={12} sm={6}>
                        <CardWrapper height="calc(100% - 16px)">
                          <HDisplay small>Reduzir sintomas de ansiedade e depressão</HDisplay>
                        </CardWrapper>
                        <Separator />
                      </Col>
                    </Row>

                    <Body center>Como está a qualidade do seu sono? Faça o teste sem compromisso e descubra agora!</Body>
                    <Separator />
                    {programPrimaryButtonConfig && (
                      <Row center={'xs'}>
                        <Col xs={12} sm={6}>
                          <GatsbyButton id={'ga-expected-results-cta'} to={programPrimaryButtonConfig.to} kind={'primary'} expanded>
                            {programPrimaryButtonConfig.text}
                          </GatsbyButton>
                        </Col>
                      </Row>
                    )}
                  </Col>
                </Row>
              </Grid>
            </Blob2SectionStyled>
          </>
        )}
      </BooleanContainer>

      {/* TODO: DELETE IF NEW LAYOUT BE APPROVED */}
      {/* <section id={'programa'}>
        <Grid>
          <Row center={'xs'}>
            <Col xs={12} md={8} lg={9}>
              <FeaturedH2>O programa não é</FeaturedH2>
              <CardWrapper>
                <Row center={'xs'}>
                  {[
                    { emoji: '🍵', text: 'Chá antes de dormir' },
                    { emoji: '🌧', text: 'Ouvir sons da natureza' },
                    { emoji: '💊', text: 'Usar remédios para dormir' },
                    { emoji: '🧙‍♀️', text: 'Uma pílula mágica' },
                  ].map((item) => (
                    <Col xs={6} sm={3} key={item.emoji}>
                      <Separator />
                      <EmojiTextVertical {...item} forbidden={true} />
                    </Col>
                  ))}
                </Row>
              </CardWrapper>
            </Col>
          </Row>
          <Row center={'xs'}>
            <Col xs={12} md={8} lg={9}>
              <FeaturedH2>O programa é</FeaturedH2>
              <CardWrapper>
                <Row center={'xs'}>
                  {[
                    { emoji: '😇', text: 'Baseado em mudança de hábitos' },
                    { emoji: '🔬', text: 'Comprovado com método científico' },
                    { emoji: '💪', text: 'Exige dedicação e compromisso' },
                    { emoji: '⭐️', text: 'Funciona, pois age nas causas da insônia' },
                  ].map((item) => (
                    <Col xs={6} sm={3} key={item.emoji}>
                      <Separator />
                      <EmojiTextVertical {...item} forbidden={false} />
                    </Col>
                  ))}
                </Row>
              </CardWrapper>
            </Col>
          </Row>
        </Grid>
      </section> */}

      <section id={'como-funciona'}>
        <Grid>
          <Row center={'xs'}>
            <Col xs={12} md={8} lg={9}>
              <FeaturedH2>Como funciona</FeaturedH2>
              <CardWrapper>
                <Row center={'xs'}>
                  <Col xs={12} sm={6}>
                    <Separator />
                    {[
                      {
                        emoji: '📈',
                        title: 'Monitoramento',
                        description:
                          'Todos os dias, vamos monitorar o seu bem-estar mental, seu  sono e os hábitos que podem estar ajudando (ou atrapalhando) sua qualidade de vida.',
                      },
                      {
                        emoji: '🤖',
                        title: 'Conhecimento',
                        description:
                          'Através de interações curtas com a Sônia, nossa inteligência artificial, você irá aprender as técnicas da TCC que podem transformar a sua relação com seu sono e saúde mental.',
                      },
                      {
                        emoji: '👩‍🏫',
                        title: 'Suporte de especialistas',
                        description:
                          'Precisa de alguma ajuda? Nossos especialistas estão disponíveis para oferecer suporte quando você precisar.',
                      },
                    ].map((item) => (
                      <EmojiTitleDescription key={item.title} {...item} />
                    ))}
                    <LargeSeparator />
                    {primaryButtonConfig && (
                      <GatsbyButton id="ga-how-it-works-evidence" to={primaryButtonConfig.to} kind="primary" expanded>
                        {primaryButtonConfig.text}
                      </GatsbyButton>
                    )}
                  </Col>
                </Row>
              </CardWrapper>
            </Col>
          </Row>
        </Grid>
      </section>

      <TestimonialSelectionSection />

      {/* TODO: DELETE IF NEW LAYOUT BE APPROVED */}
      {/* <ProfileSelectionSection /> */}
      {/* <section id={'perguntas-frequentes'}>
        <Grid>
          <FeaturedH2>Perguntas frequentes</FeaturedH2>
          <Row center={'xs'}>
            <Col xs={12} md={8} lg={9}>
              <FaqComponent
                allQuestions={PatientFAQuestions}
                questionsToShow={[PatientFAQuestionKey.CBTI, PatientFAQuestionKey.HowItWorks, PatientFAQuestionKey.IUseMedication]}
              />

              <Row center={'xs'} mt>
                <Col xs={12} sm={6}>
                  <GatsbyButton id="ga-faq" to={appPaths.faq.path} kind="link" expanded>
                    Ver mais perguntas e respostas
                  </GatsbyButton>
                </Col>
              </Row>
            </Col>
          </Row>
          <LargeSeparatorRow />
        </Grid>
      </section> */}

      <section id={'media'}>
        <LoadableHcpLandingPageMediaSection />
      </section>

      <section id={'refererencias'}>
        <Grid>
          <LargeSeparatorRow />
          <Row mt mb center={'xs'}>
            <Col xs={12} md={8} lg={9}>
              <Footnote {...footnotes} />
              <Row center={'xs'} mt>
                <Col xs={12} sm={6}>
                  <GatsbyButton id="ga-evidences" to={appPaths.evidences.path} kind="link" expanded>
                    Ver mais evidências científicas
                  </GatsbyButton>
                </Col>
              </Row>
            </Col>
          </Row>
          <LargeSeparatorRow />
          <LargeSeparatorRow />
          <LargeSeparatorRow />
        </Grid>
      </section>

      {/* TODO: DELETE IF NEW LAYOUT BE APPROVED */}
      {/* <PatientGetStartedSection /> */}
    </IndexLayout>
  );
};

const footnotes: FootnoteProps = {
  references: [
    {
      title:
        'Mitchell, M. D., Gehrman, P., Perlis, M., & Umscheid, C. A. (2012). Comparative effectiveness of cognitive behavioral therapy for insomnia: a systematic review. BMC family practice, 13(1), 40.Chicago',
      url: 'https://bmcfampract.biomedcentral.com/articles/10.1186/1471-2296-13-40',
    },
    {
      title:
        'Jacobs, G. D., Pace-Schott, E. F., Stickgold, R., & Otto, M. W. (2004). Cognitive behavior therapy and pharmacotherapy for insomnia: a randomized controlled trial and direct comparison. Archives of internal medicine, 164(17), 1888-1896.Chicago',
      url: 'https://jamanetwork.com/journals/jamainternalmedicine/fullarticle/217394',
    },
  ],
};
