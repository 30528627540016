import { GatsbyButton } from '@components/atm.button/button.component';
import TestimonialCard, { ITestimonialCardProps } from '@lp-root/src/components/org.testimonial-card/testimonial-card.component';
import { appPaths } from '@lp-root/src/utils/path';
import { Col, FeaturedH2, Grid, Row, Separator } from '@web/atomic';
import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';

interface TestimonialSelectionSectionProps {
  hideBottomLink?: boolean;
  showAllOnMobile?: boolean;
}

export const TestimonialSelectionSection: React.FunctionComponent<TestimonialSelectionSectionProps> = (props) => {
  const data = useStaticQuery(graphql`{
  nina: file(relativePath: {eq: "nina.png"}) {
    childImageSharp {
      gatsbyImageData(width: 754, layout: CONSTRAINED)
    }
  }
  wania: file(relativePath: {eq: "wania.png"}) {
    childImageSharp {
      gatsbyImageData(width: 754, layout: CONSTRAINED)
    }
  }
  miquelina: file(relativePath: {eq: "miquelina.png"}) {
    childImageSharp {
      gatsbyImageData(width: 754, layout: CONSTRAINED)
    }
  }
}
`);

  const testimonials: ITestimonialCardProps[] = [
    {
      name: 'Nina',
      insomniaAge: '5 anos',
      image: data.nina.childImageSharp.gatsbyImageData,
      quote: 'Aprendi muita coisa. Hoje me sinto outra pessoa',
      beforeTimeInBed: 8 * 60 + 1,
      beforeTimeAsleep: 3 * 60 + 38,
      treatmentDuration: '9 semanas',
      afterTimeInBed: 6 * 60 + 51,
      afterTimeAsleep: 6 * 60 + 22,
      youtubeUrl: 'https://www.youtube.com/embed/cYOHjqfADYc',
      maxTime: 10 * 60 + 17,
    },
    {
      name: 'Wania',
      insomniaAge: '2 anos',
      image: data.wania.childImageSharp.gatsbyImageData,
      quote: 'Mudou muito meu dia. Estou melhor após o programa',
      beforeTimeInBed: 10 * 60 + 17,
      beforeTimeAsleep: 5 * 60 + 30,
      treatmentDuration: '8 semanas',
      afterTimeInBed: 8 * 60 + 37,
      afterTimeAsleep: 8 * 60 + 14,
      youtubeUrl: 'https://www.youtube.com/embed/7goawFAZ0TQ',
      maxTime: 10 * 60 + 17,
    },
    {
      name: 'Miquelina',
      insomniaAge: '20 anos',
      image: data.miquelina.childImageSharp.gatsbyImageData,
      quote: 'Dá para melhorar sem remédio com estas técnicas',
      beforeTimeInBed: 6 * 60 + 48,
      beforeTimeAsleep: 5 * 60 + 36,
      treatmentDuration: '7 semanas',
      afterTimeInBed: 7 * 60 + 3,
      afterTimeAsleep: 6 * 60 + 50,
      youtubeUrl: 'https://www.youtube.com/embed/pIodWlLbhlE',
      maxTime: 10 * 60 + 17,
    },
  ];

  return (
    <section id={'depoimentos'}>
      <Grid>
        <FeaturedH2>#somosvigilantes</FeaturedH2>
        <Row center={'xs'}>
          {testimonials.map((t, i) => (
            <Col xs={(i === 0 || props.showAllOnMobile) && 12} sm={6} md={3} key={t.name}>
              <TestimonialCard {...t} />
              <Separator />
            </Col>
          ))}
        </Row>
        {props.hideBottomLink || (
          <Row center={'xs'}>
            <Col xs={12}>
              <GatsbyButton id="ga-testimonials" to={appPaths.testimonials.path} kind="link" expanded>
                Ver outras histórias
              </GatsbyButton>
            </Col>
          </Row>
        )}
      </Grid>
    </section>
  );
};
