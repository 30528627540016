import { GatsbyButton } from '@components/atm.button/button.component';
import { Col, Grid, HDisplay, Row, Separator } from '@web/atomic';
import { ForcedFade } from '@web/atomic/legacy/obj.animation/animation.component.style';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage, withArtDirection } from 'gatsby-plugin-image';
import * as React from 'react';
import {
  LandingPageContentStyled,
  LandingPageImageStyled,
  LandingPageSectionStyled,
  LandingPageTitleStyled,
} from './landing-page-hero-section.styled';
import { PartnersLogoComponent } from '@components/mol.partners-logo/PartnersLogoComponent';

interface LandingPageHeroSectionProps {
  title: string;
  headline: JSX.Element;
  primaryButton?: {
    text: string;
    to: string;
    id: string;
  };
  secondaryButton?: {
    id: string;
    text: string;
    to?: string;
    onClick?: () => void;
  };
}

const ComponentToBeMemoized: React.FunctionComponent<LandingPageHeroSectionProps> = (props) => {
  const data = useStaticQuery(graphql`
    {
      mobileImage: file(relativePath: { eq: "mobile-hero-img.png" }) {
        childImageSharp {
          gatsbyImageData(width: 768, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      desktopImage: file(relativePath: { eq: "hero-img.png" }) {
        childImageSharp {
          gatsbyImageData(width: 601, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      eretzbio: file(relativePath: { eq: "logo_eretz-einstein-branco.png" }) {
        childImageSharp {
          gatsbyImageData(width: 400, layout: FIXED, placeholder: NONE)
        }
      }
    }
  `);

  const images = withArtDirection(getImage(data.desktopImage.childImageSharp.gatsbyImageData), [
    {
      media: '(max-width: 1024px)',
      image: getImage(data.mobileImage.childImageSharp.gatsbyImageData),
    },
  ]);

  return (
    <LandingPageSectionStyled id={'inicio'}>
      <Grid>
        <Row middle={'xs'}>
          <Col xs={12} md={5}>
            <LandingPageContentStyled>
              <LandingPageTitleStyled>
                <HDisplay kind="light">{props.title}</HDisplay>
                {props.headline}
              </LandingPageTitleStyled>
              <Separator />
              <ForcedFade show={true} delay={'.5s'}>
                {props.primaryButton && (
                  <Row>
                    <Col xs={12}>
                      <GatsbyButton id={props.primaryButton.id} to={props.primaryButton.to} kind={'call-to-action'} expanded>
                        {props.primaryButton.text}
                      </GatsbyButton>
                      <Separator />
                    </Col>
                  </Row>
                )}
                {props.secondaryButton && (
                  <Row center={'xs'}>
                    <Col>
                      <GatsbyButton
                        id={props.secondaryButton.id}
                        onClick={props.secondaryButton.onClick}
                        to={props.secondaryButton.to}
                        kind={'link'}
                        light
                      >
                        {props.secondaryButton.text}
                      </GatsbyButton>
                      <Separator />
                    </Col>
                  </Row>
                )}
              </ForcedFade>
              <Separator />
              <Separator />
              <PartnersLogoComponent kind={'light'} imgs={data.eretzbio.childImageSharp.gatsbyImageData} />
            </LandingPageContentStyled>
          </Col>
        </Row>
      </Grid>
      <LandingPageImageStyled>
        <GatsbyImage loading="eager" image={images} alt="Moça dormindo" imgStyle={{ objectPosition: `top center` }} />
      </LandingPageImageStyled>
    </LandingPageSectionStyled>
  );
};

export const LandingPageHeroSection: React.FunctionComponent<LandingPageHeroSectionProps> = React.memo(
  ComponentToBeMemoized,
  (prevProps, nextProps) => prevProps.primaryButton?.text === nextProps.primaryButton?.text
);
