import { Spacing , GrayColor } from '@web/atomic';
import styled from 'styled-components';

export const PartnersLogoComponentStyle = styled.div`
  @media all and (max-width: 48em) {
    margin-bottom: ${Spacing.Large}px;
  }
`;

export type PartnersLogoComponentKind = 'light' | 'default';

export const getPartnersLogoComponentH5Color = (kind: PartnersLogoComponentKind): string => {
  if (kind === 'light') {
    return GrayColor.GrayXXLight;
  }
  if (kind === 'default') {
    return GrayColor.GrayDark;
  }
};
