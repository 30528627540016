import loadable from '@loadable/component';
import { LoadableLoadingWrapper } from '@lp-src/utils/loadable/loadable-loading-wrapper.component';
import { ThumbShimmerBoxStyled } from '@web/atomic/legacy/mol.shimmer/shimmer.component.style';
import * as React from 'react';

const InternalLoadableHcpLandingPageMediaSection = loadable(
  () => import('./media-section.component'),
  {
    fallback: (
      <ThumbShimmerBoxStyled height={'48px'} />
    )
  });

export const LoadableHcpLandingPageMediaSection = () => (
  <LoadableLoadingWrapper>
    <InternalLoadableHcpLandingPageMediaSection />
  </LoadableLoadingWrapper>
);

