import { toHour } from '@global/utils/date/to-hour';
import { H3, H4, Hbox, LightDivisor, Separator } from '@web/atomic';
import SvgIcPlay from '@web/atomic/atm.svg-icon/ic-play';
import { CardWrapper, PaddingWrapper } from '@web/atomic/atm.wrapper/wrapper.component';
import { Video } from '@web/atomic/legacy/atm.video/video.component';
import { BooleanContainer } from '@web/atomic/legacy/obj.abstract-container';
import { ForcedFade } from '@web/atomic/legacy/obj.animation/animation.component.style';
import { Modal } from '@web/atomic/legacy/obj.modal';
import { ProgressGauge } from '@web/atomic/mol.progress-gauge/progress-gauge.component';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import * as React from 'react';
import { Clickable } from '../obj.clickable/clickable.component.style';
import { TestimonialCaptionStyled, TestimonialQuoteStyled } from './testimonial-card.component.style';

export interface ITestimonialCardProps {
  name: string;
  insomniaAge: string;
  image: IGatsbyImageData;
  quote: string;
  beforeTimeInBed: number;
  beforeTimeAsleep: number;
  treatmentDuration: string;
  afterTimeInBed: number;
  afterTimeAsleep: number;
  youtubeUrl: string;
  maxTime: number;
}

const TestimonialCard: React.FunctionComponent<ITestimonialCardProps> = (props) => {
  const {
    name,
    insomniaAge,
    image,
    quote,
    beforeTimeInBed,
    beforeTimeAsleep,
    treatmentDuration,
    afterTimeInBed,
    afterTimeAsleep,
    maxTime,
  } = props;

  return (
    <BooleanContainer>
      {(bool) => (
        <>
          <ForcedFade show={true}>
            <CardWrapper noPadding>
              <PaddingWrapper>
                <Hbox>
                  <Hbox.Item>
                    <H3 cell>{name},</H3>
                    <TestimonialCaptionStyled>Tinha insônia há {insomniaAge}</TestimonialCaptionStyled>
                  </Hbox.Item>
                  <Hbox.Item wrap vAlign="center">
                    <Clickable onClick={bool.setTrue}>
                      <SvgIcPlay />
                    </Clickable>
                  </Hbox.Item>
                </Hbox>
              </PaddingWrapper>
              <GatsbyImage image={image} alt={`Foto de ${name}`} />
              <PaddingWrapper>
                <TestimonialQuoteStyled>{quote}</TestimonialQuoteStyled>
                <Separator />
                <LightDivisor />
                <Separator />
                <H4>Antes do programa</H4>
                <ProgressGauge
                  maxValue={maxTime}
                  backgroundValue={beforeTimeInBed}
                  foregroungValue={beforeTimeAsleep}
                  leftText={
                    <>
                      Dormindo: <strong>{toHour(beforeTimeAsleep)}</strong>
                    </>
                  }
                  rightText={
                    <>
                      Na cama: <strong>{toHour(beforeTimeInBed)}</strong>
                    </>
                  }
                  kind={'negative'}
                />
                <Separator />
                <H4>Após {treatmentDuration}</H4>
                <ProgressGauge
                  maxValue={maxTime}
                  backgroundValue={afterTimeInBed}
                  foregroungValue={afterTimeAsleep}
                  leftText={
                    <>
                      Dormindo: <strong>{toHour(afterTimeAsleep)}</strong>
                    </>
                  }
                  rightText={
                    <>
                      Na cama: <strong>{toHour(afterTimeInBed)}</strong>
                    </>
                  }
                  kind={'positive'}
                />
              </PaddingWrapper>
            </CardWrapper>
          </ForcedFade>
          <Modal opened={bool.value} onClose={bool.setFalse} noPadding overflow="hidden">
            <Video url={props.youtubeUrl} />
          </Modal>
        </>
      )}
    </BooleanContainer>
  );
};

export default TestimonialCard;
