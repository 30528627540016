import { ForcedFade } from '@web/atomic/legacy/obj.animation/animation.component.style';
import * as React from 'react';
import { InView } from 'react-intersection-observer';

import { EmojiBallStyled, EmojiBallTextStyled } from './emoji-ball.component.style';

export interface EmojiBallProps {
  emoji: string;
  forbidden?: boolean;
}

export const EmojiBall: React.FC<EmojiBallProps> = (props: EmojiBallProps) => (
  <InView triggerOnce={true}>
    {({ inView, ref }) => {
      return (
        <ForcedFade show={inView} ref={ref}>
          <EmojiBallStyled {...props}>
            <EmojiBallTextStyled {...props}>{props.emoji}</EmojiBallTextStyled>
          </EmojiBallStyled>
        </ForcedFade>
      );
    }}
  </InView>
);
