import { headerId } from '@components/org.menu/client-header.utils';
import { getTopElementHeight, ScrollElement, scrollToElement } from '@web/utils/scroll';
import * as React from 'react';
import { FootnoteRefStyled } from './footnote.style';
import { getFootnoteBackrefId, getFootnoteRefId } from './footnote.utils';

export interface FootnoteRefProps {
  index: number;
}

export const FootnoteRef: React.FunctionComponent<FootnoteRefProps> = (props) => {
  const handleClick = () => {
    scrollToElement(getFootnoteRefId(props.index), { top: getTopElementHeight(headerId) });
  };
  return (
    <>
      <sup>
        <FootnoteRefStyled onClick={handleClick}>
          <ScrollElement name={getFootnoteBackrefId(props.index)} />
          {props.index + 1}
        </FootnoteRefStyled>
      </sup>
    </>
  );
};
