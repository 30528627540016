/* eslint-disable @typescript-eslint/no-var-requires */
import { getLevelXColor } from '@global/utils/ui/color-fn';
import { BrandColor, GrayColor } from '@web/atomic/legacy/obj.constants/constants';
import styled, { css } from 'styled-components';

export const ColorBackground = styled.div`
  background-color: ${(props) => props.color};
`;

export const NeutralBackground = styled.div`
  background-color: ${GrayColor.Neutral};
`;

const blob1Path = require('@lp-root/src/assets/images/blob1.svg').default;
const blob1BackgroundCommonStyle = css`
  content: '';
  display: block;
  position: absolute;
  background-image: url(${blob1Path});
  background-size: cover;
  width: 100%;
  z-index: -1;
`;
const top = 80;
export const Blob1SectionStyled = styled.section<{ topOverflow?}>`
  position: relative;
  background-color: ${GrayColor.GrayXXLight};
  margin-top: ${(props) => (props.topOverflow ? 0 : `${top}px`)};
  &:before {
    ${blob1BackgroundCommonStyle}
    top: -${top}px;
    height: 140px;
  }
  &:after {
    ${blob1BackgroundCommonStyle}
    background-position: bottom;
    bottom: -180px;
    height: 320px;
  }
`;

const blob2Path = require('@lp-root/src/assets/images/blob2.svg').default;
const blob2CommonStyle = css`
  content: '';
  display: block;
  position: absolute;
  background-image: url(${blob2Path});
  background-size: cover;
  width: 100%;
  z-index: -1;
`;
const bottom = 110;
export const Blob2SectionStyled = styled.section`
  position: relative;
  background-color: ${GrayColor.GrayXXLight};
  margin-bottom: ${bottom}px;
  &:before {
    ${blob2CommonStyle}
    top: -80px;
    height: 140px;
  }
  &:after {
    ${blob2CommonStyle}
    background-position: bottom;
    bottom: -${bottom}px;
    height: 180px;
  }
`;

const blob4Path = require('@lp-root/src/assets/images/blob4.svg').default;
export const Blob4SectionStyled = styled.section`
  position: relative;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(${blob4Path});
`;

////////////////////////////////////////////////////////////////////////////////////////////////////

export interface GradientBackgroundProps {
  level: number;
  disableFitWindowHeight?: boolean;
}

const getTopBackgroundColor = (level: number) => {
  switch (level) {
    case 0:
      return BrandColor.Charade;
    default:
      return getLevelXColor(BrandColor.EbonyClay, level - 1);
  }
};

const getBottomBackgroundColor = (level: number) => {
  switch (level) {
    case 0:
      return BrandColor.Woodsmoke;
    default:
      return getLevelXColor(BrandColor.EbonyClay, level - 1);
  }
};

export const GradientBackground = styled.div<GradientBackgroundProps>`
  background-image: ${(props) => `linear-gradient(${getTopBackgroundColor(props.level)}, ${getBottomBackgroundColor(props.level)})`};
  min-height: ${(props) => (props.disableFitWindowHeight ? '0' : '100vh')};
`;
