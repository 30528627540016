import { Assesment } from '@global/utils/remote-graphql-types';
import { getStorage, StorageType } from '@web/data/storage/storage.provider';

const ASSESMENT_KEY = 'UserAssesment';

class AssesmentDataSource {
  private sessionStorage = getStorage(StorageType.Session);

  get assesment() {
    const assesmentString = this.sessionStorage.get(ASSESMENT_KEY);
    return assesmentString ? (JSON.parse(assesmentString) as Assesment) : null;
  }
  set assesment(value: Assesment) {
    this.sessionStorage.put(ASSESMENT_KEY, JSON.stringify(value));
  }
}

export default new AssesmentDataSource();
