import { headerId } from '@components/org.menu/client-header.utils';
import { getTopElementHeight, ScrollElement, scrollToElement } from '@web/utils/scroll';
import * as React from 'react';
import { FootnoteBackrefStyled, FootnoteStyled } from './footnote.style';
import { getFootnoteBackrefId, getFootnoteRefId } from './footnote.utils';

export interface FootnoteProps {
  references: {
    url: string;
    // if no text is given, the url will be used instead
    title?: string;
  }[];
}

export const Footnote: React.FunctionComponent<FootnoteProps> = (props) => {
  const handleClickHOF = (i: number) => () => {
    scrollToElement(getFootnoteBackrefId(i), { top: getTopElementHeight(headerId) });
  };
  return (
    <FootnoteStyled>
      <hr />
      <ol>
        {props.references.map((r, i) => (
          <li key={i}>
            <ScrollElement className="react-scroll-element" name={getFootnoteRefId(i)} />
            <p>
              <a target="_blank" rel="noreferrer" href={r.url}>
                {r.title || r.url}
              </a>
            </p>
            <FootnoteBackrefStyled onClick={handleClickHOF(i)}>↩</FootnoteBackrefStyled>
          </li>
        ))}
      </ol>
    </FootnoteStyled>
  );
};
